jQuery(function($) {
    /* Hauptmenü in der Breite anpassen */
    $(window).on('resize', function(event) {
      var containerWidth = $('header .container').width();
      $('ul.mainmenu ul').width(containerWidth);
    }).trigger('resize');

    /* Offcanvas */
    $(window).on('resize', function(event) {
      var canvasHeight = $( window ).height() - $('header').height();
      $('.offcanvas-collapse').css('height', canvasHeight + 'px');
    });
    $(window).on('load', function(event) {
      $(this).trigger('resize');
    });

    $('[data-toggle="offcanvas"]').unbind('click').bind('click', function (event) {
      $(this).children('.fas').toggleClass('fa-times');
      $('html').toggleClass('offcanvas-open');
    });

    /* Bilder im Inhaltsbereich oder bei den Kontakten */
    $('[itemprop="articleBody"] img, .contact-misc img').each(function(index, el) {
      var $img = $(this);

      if ( $img.parent('a').hasClass('lightbox') ) return;
      
      $img.wrap('<figure class="item-image bg-light"></figure>');

      var title = $img.attr('title');
      if ( typeof title !== 'undefined' ) {
        $img.after('<figcaption>'+title+'</figcaption>')
      }
    });

    /* Chronoforms Stile deaktivieren */
    $('.chronoforms').removeClass('semanticui-body').children('form').removeClass('ui form');
});
